require('jquery-validation');
require('jquery-validation-unobtrusive');

export default class BootstrapMVCForm {
    constructor(scope) {
        if (typeof scope === 'undefined') {
            console.warn('WARNING! Parameters missing - Could not initialize BootstrapMVCForm.'); // eslint-disable-line no-console
            return;
        }

        this.$scope = scope;

        this.initialize();
    }

    initialize() {
        const formGroups = this.$scope.find('.form-group');
        formGroups.each((index, elem) => {
            const $el = $(elem);
            if ($el.hasClass('form-group')) {
                const $control = $el.find('.form-control, .form-control .selector');
                if ($el.find('.field-validation-error').length) {
                    BootstrapMVCForm.addError($el);
                }

                $control.on('blur', { $parent: $el }, function (event) { // eslint-disable-line func-names
                    if ($(this).data('removespaces')) {
                        $(this).val((_, v) => v.replace(/\s+/g, ''));
                        $(this).trigger("change"); // Trigger change to trigger fetching the address one more time in the postalcode validator.
                    }
                    BootstrapMVCForm.validate($(this), event.data.$parent, true);
                });

                $control.on('change keyup', { $parent: $el }, function (event) { // eslint-disable-line func-names
                    if (BootstrapMVCForm.valueIsSet($(this))) {
                        BootstrapMVCForm.validate($(this), event.data.$parent);
                    }
                });
            } else {
                throw ('Scope is not properly defined'); // eslint-disable-line no-throw-literal
            }
        });

        const submit = this.$scope.find('input[type="submit"], button[type="submit"]');
        submit.on('click', () => {
            formGroups.each((index, elem) => {
                const $el = $(elem);
                const $control = $el.find('.form-control, .form-control-select');
                if ($control.length > 1 && $($control[0]).data('grouphug')) {
                    BootstrapMVCForm.validateElGroup($($control[0]), $el, true, true);
                } else {
                    BootstrapMVCForm.validate($control, $el, true);
                }
            });
        });
    }

    static addError($parent) {
        $parent.addClass('has-error');
        $parent.removeClass('has-success');
        $parent.find('.field-validation-error').addClass('validation-message');
    }

    static removeError($parent) {
        $parent.removeClass('has-error');
        $parent.find('.validation-message').removeClass('validation-message');
    }

    static removeSuccess($parent) {
        $parent.removeClass('has-success');
    }

    static addSuccess($parent) {
        $parent.addClass('has-success');
    }

    static validateEl($el, $parent, canAdd) {
        try {
            if ($el.valid()) {
                BootstrapMVCForm.removeError($parent);
                if (canAdd && BootstrapMVCForm.valueIsSet($el)) {
                    BootstrapMVCForm.addSuccess($parent);
                }
            } else {
                BootstrapMVCForm.removeSuccess($parent);
                if (canAdd) {
                    BootstrapMVCForm.addError($parent);
                }
            }
        } catch (e) { } // eslint-disable-line no-empty
    }

    static lastInGroup($els, $el) {
        const highestIndex = $els.length - 1;
        if ($els.index($el) === highestIndex) {
            return true;
        }
        return false;
    }

    static validateElGroup($el, $parent, canAdd, forceValidate) {
        const $els = $(`.form-control[data-grouphug='${$el.data('grouphug')}']`);
        let valid = true;

        $els.each((index, elem) => {
            if (!$(elem).valid()) {
                valid = false;
            }
        });
        if (valid) {
            BootstrapMVCForm.removeError($parent);
            if (canAdd) {
                BootstrapMVCForm.addSuccess($parent);
            }
        } else {
            BootstrapMVCForm.removeSuccess($parent);
            if (canAdd && BootstrapMVCForm.lastInGroup($els, $el)) {
                BootstrapMVCForm.addError($parent);
            }
            if (forceValidate) {
                BootstrapMVCForm.addError($parent);
            }
        }
    }

    static valueIsSet($el) {
        if ($el.val().split('').length > 1) {
            return true;
        }
        return false;
    }

    static validate($el, $parent, canAdd) {
        if ($el.data('grouphug')) {
            BootstrapMVCForm.validateElGroup($el, $parent, canAdd);
        } else {
            BootstrapMVCForm.validateEl($el, $parent, canAdd);
        }
    }

    scrollToFirstError() {
        const firstErrorEl = this.$scope.find('#IsAlternativeInvoiceEmailAddressNotification:visible').length > 0
            ? this.$scope.find('#IsAlternativeInvoiceEmailAddressNotification:visible')
            : this.$scope.find('.field-validation-error:visible');

        const inputEl = firstErrorEl.parent();

        if (!inputEl) return;

        const headerEl = $('header');
        const scrollPadding = 80;

        $('html, body').animate({
            scrollTop: inputEl.offset().top - headerEl.height() - scrollPadding
        }, 1000);
    }

    hasValidationErrors() {
        return this.$scope.find('.field-validation-error:visible').length > 0
            || this.$scope.find('#IsAlternativeInvoiceEmailAddressNotification:visible').length > 0;
    }
}
