/* eslint no-unused-vars: 0 */

import BootstrapMVCForm from '../misc/bootstrap-mvcform';
import Notification from '../controllers/notification';

const notificationForm = new BootstrapMVCForm($(window.NotificationFormElement));
const notification = new Notification(window.NotificationUrl);

$(() => {
    $(window.NotificationFormElement).on('submit', (event) => {
        event.preventDefault();
        notification.addNotification();
    });
});
